import React from 'react';
import Header from './Header';
import Footer from './Footer';
import { baseURL } from '../api/index'


class AboutMe extends React.Component {

    componentDidMount = () => {
        let head = document.getElementsByTagName("head");
        let title = document.getElementsByTagName("title");
        title[0].innerHTML = "O mnie - Torebkowa Mania Aneta Kubisiewicz";
        let description = document.createElement("meta");
        description.setAttribute("name", "description");
        description.setAttribute("content", "Pracownia krawiecka Aneta Kubisiewicz. Szeroki zakres usług, pasmanteria. Zobacz więcej!");
        head[0].appendChild(description)

    }

    render() {
        return(
            <div>
                <Header/>
                <div className="about-me-overall">
                <div className="about-me-container">
                    <div className="about-me-item">
                        <img className="about-me-image" src="../my-photo.jpg" alt="my-photo"/>
                        <div className="about-me-div">
                            <h2 className="about-me-header">Witam w Torebkowej Manii!</h2>
                        <p className="about-me-paragraph">
                            Zamiłowanie do szycia poprowadziło mnie do stworzenia tego, co właśnie oglądasz. Nazwa mojego sklepu nie oddaje w pełni tego, co możesz u mnie znaleźć.
                            "Torebkowa Mania", to wbrew pozorom nie tylko torebki. To cała pracownia krawiecka z szerokim zakresem usług. Znajdziesz u mnie także bardzo przydatne akcesoria krawieckie dla domowej krawcowej. Potrafię także szyć na miarę. 
                            </p>

                            <p className="about-me-paragraph">Z zawodu jestem Krawcem Odzieży Damskiej Lekkiej, swoje wykształcenie zdobyłam juz ponad 20 lat temu i od tego czasu ciągle poszerzam swoje umiejętności w praktyce. 
                            Przez większość tego czasu prowadziłam własny zakład krawiecki z szerokim zakresem usług oraz szyciem na miarę. 
                            Od dawna jednak w planach miałam szycie według własnego pomysłu. Zainteresowałam się szyciem torebek damskich z tkanin wodoodpornych oraz ekoskóry. Bardzo podoba mi się tworzenie nowych rzeczy, tymbardziej, że są one wykonane od pierwszego do ostatniego szwu przeze mnie, dzięki czemu mogę kontrolować ich jakość, która jest dla mnie bardzo ważna. Mogę zadbać o każdy szczegół.</p>

                            <p className="about-me-paragraph">Najważniejszy dla mnie jest zadowolony klient, więc zawsze wkładam serce do tego co wykonuję. Nieważne czy jest to organizer na torebkę czy szycie sukienki na miarę. Uśmiech szczęśliwego klienta to coś, co daje mi motywację do dalszej pracy i rozwoju.</p>

                            <p className="about-me-paragraph">Zapraszam serdecznie do zapoznania się z moja ofertą w zakładce <a style={{textDecoration:"underline"}} href={`${baseURL}/sklep`}>Sklep</a> oraz z <a style={{textDecoration:"underline"}} href={`${baseURL}/cennik`}>cennikiem usług krawieckich</a>. Jeżeli jesteś z Wrocławia lub okolic do mojej nowo otwartej Pracowni Krawieckiej w sercu miasta, na Nadodrzu na ulicy Pomorskiej 31a.</p>

                            <p>Zapraszam serdecznie<br/>
                            Aneta Kubisiewicz</p>
                            
                        </div>
                    </div>
                </div>
                </div>
                <Footer/>
            </div>
        )
    }
}

export default AboutMe
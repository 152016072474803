const axios = require('axios');

export default axios.create({
    baseURL:'https://kam237zasada.usermd.net',
    headers: {"Access-Control-Allow-Origin": "*"}
});

export const baseURL='https://torebkowamania.pl'

export const serverbaseURL='https://kam237zasada.usermd.net'




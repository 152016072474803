import React from 'react';

class Settings extends React.Component {

    render() {
        return(
            <div>
                Ustawienia
            </div>
        )
    }
}

export default Settings;